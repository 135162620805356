import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppLayout from './Components/AppLayout/AppLayout';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions/TermsAndConditions';
import NavigationBar from './Components/NavigationBar/NavigationBar';
import Footer from './Components/Footer/Footer';
import NotFound from './Pages/NotFound/NotFound';

function App() {
  return (
    <BrowserRouter>
        <NavigationBar/>
        <Routes>
            <Route path="/" element={<AppLayout/>}/>
            <Route path="/privacy" element={<PrivacyPolicy/>}/>
            <Route path="/termsofservice" element={<TermsAndConditions/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
        <Footer/>
    </BrowserRouter>
  )
}

export default App;

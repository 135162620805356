/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import React from 'react'
import NavigationBar from '../NavigationBar/NavigationBar'
import Footer from '../Footer/Footer'
import Hero from '../../Pages/Hero/Hero'
import Products from '../../Pages/Products/Products'
import About from '../../Pages/About/About'
import Benefits from '../../Pages/Benefits/Benefits'
import Contact from '../../Pages/Contact/Contact'
import Pricing from '../../Pages/Pricing/Pricing'

export default function AppLayout() {
  return (
    <>
      <Hero/>
      <About/>
      <Benefits/>
      <Products/>
      <Pricing/>
      <Contact/>
    </>
  )
}

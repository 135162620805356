/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react'
import logo from '../../assets/SPTA Logo_Horizontal_FINAL.jpg';
import './NavigationBar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

export default function NavigationBar() {

  const [activeLink, setActiveLink] = useState('home');

  useEffect(() => {
    const sectionIds = ['home', 'about', 'benefits', 'products', 'pricing', 'contact'];
    // Function to determine the active section while scrolling
    function getActiveSection() {
      for (let i = sectionIds.length - 1; i >= 0; i--) {
        const section = document.getElementById(sectionIds[i]);
        // console.log(section);
        if (section !== null) {
          const rect = section.getBoundingClientRect();
          if (rect.top <= 360 && rect.bottom >= 400) {
            //Set the active link based on the section Id
            setActiveLink(sectionIds[i]);
            break;
          }
        }
      }
    }

    window.addEventListener('scroll', getActiveSection);

    //remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', getActiveSection);
    };
  }, []);

  return (
      <div className='navbar' >
        <img className='nav-logo' src={logo}/>
        <nav className='nav-flex'>
          <div className='nav-link-container'>
            <NavHashLink to={'/#home'} className={`nav-link ${activeLink === 'home' ? 'active-nav' : ''}`}>Home</NavHashLink>
          </div>
          <div className='nav-link-container'>
            <NavHashLink to={'/#about'} className={`nav-link ${activeLink === 'about' ? 'active-nav' : ''}`}>About</NavHashLink>
          </div>
          <div className='nav-link-container'>
            <NavHashLink to={'/#benefits'} className={`nav-link ${activeLink === 'benefits' ? 'active-nav' : ''}`}>Benefits</NavHashLink>
          </div>
          <div className='nav-link-container'>
            <NavHashLink to={'/#products'} className={`nav-link ${activeLink === 'products' ? 'active-nav' : ''}`}>Products</NavHashLink>
          </div>
          <div className='nav-link-container'>
            <NavHashLink to={'/#pricing'} className={`nav-link ${activeLink === 'pricing' ? 'active-nav' : ''}`}>Pricing</NavHashLink>
          </div>
          <div className='nav-link-container'>
            <NavHashLink  to={'/#contact'} className={`nav-link contact-link ${activeLink === 'contact' ? 'active-nav' : ''}`}>Contact <FontAwesomeIcon style={{paddingLeft: 8}} icon={faEnvelope}/></NavHashLink>
          </div>
        </nav>
        <div className='mobile-svg'>
          <Dropdown>
              <Dropdown.Toggle>
                  <FontAwesomeIcon className='toggle-icon' icon={faBars}/>
              </Dropdown.Toggle>
              <Dropdown.Menu bsPrefix='dropdown-menu nav-dropdown'>
                <div style={{display:'flex', flexDirection: 'column'}}>
                  <NavHashLink to={'/#home'} className={`dropdown-link ${activeLink === 'home' ? 'active-mobile' : ''}`}>Home</NavHashLink>
                  <NavHashLink to={'/#about'} className={`dropdown-link ${activeLink === 'about' ? 'active-mobile' : ''}`}>About</NavHashLink>
                  <NavHashLink to={'/#benefits'} className={`dropdown-link ${activeLink === 'benefits' ? 'active-mobile' : ''}`}>Benefits</NavHashLink>
                  <NavHashLink to={'/#products'} className={`dropdown-link ${activeLink === 'products' ? 'active-mobile' : ''}`}>Products</NavHashLink>
                  <NavHashLink to={'/#pricing'} className={`dropdown-link ${activeLink === 'pricing' ? 'active-mobile' : ''}`}>Pricing</NavHashLink>
                  <NavHashLink to={'/#contact'} className={`dropdown-link ${activeLink === 'contact' ? 'active-mobile' : ''}`}>Contact<FontAwesomeIcon style={{paddingLeft: 8}} icon={faEnvelope}/></NavHashLink>
                </div>
              </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
  )
}

/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React from 'react'
import './Contact.scss'
import HubspotForm from 'react-hubspot-form';
import HubspotContactForm from '../../Components/HubspotContactForm/HubspotContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
// import image from '../../assets/'

export default function Contact() {
  return (
    <section className='section contact-section' id='contact'>
      <h1 className='header contact-main-header'>Get in Contact</h1>
      {/* <div className='contact-container'>
          <div className='colored-background'>
          </div>
          <div className='contact-fields-container'>

              <div className='user-info'>
                  <input className='user-input' placeholder='Frist Name'/>
                  <input className='user-input' placeholder='Last Name'/>
              </div>
              <div className='user-info'>
                  <input className='user-input' placeholder='Email'/>
                  <input className='user-input' placeholder='Phone Number'/>
              </div>
              <input className='subject' placeholder='Subject'></input>
              <textarea className='message' placeholder='Message'/>
              <button className='custom-btn submit-btn'>Send Message</button>
          </div>
      </div> */}
      {/* <HubspotForm 
        portalId='44519729'
        formId='861fc363-08dc-4423-af00-99738d1dc9ea'
        onSubmit={() => console.log('Submit!')}
        onReady={(form) => console.log('Form ready!')}
        loading={<div>Loading...</div>}
        /> */}
        <div className='contact-container'>
            <div className='colored-background'>
                <h2 className='contact-header'>Contact Us</h2>
                <div className='our-info'>
                    <div className='svg-row'>
                        <FontAwesomeIcon className='contact-svg' icon={faPhone} />
                        <h5 className='svg-text'>+1 (330)-659-0350</h5>
                    </div>
                    <div className='svg-row'>
                        <FontAwesomeIcon className='contact-svg' icon={faEnvelope} />
                        <h5 className='svg-text'>contact@sptaplans.com</h5>
                    </div>
                    <div className='svg-row'>
                        <FontAwesomeIcon className='contact-svg' icon={faAddressBook} />
                        <div>
                            <h5 className='svg-text'>4615 W Streetsboro Rd.</h5>
                            <h5 className='svg-text'>STE 203</h5>
                            <h5 className='svg-text'>Richfield, OH 44236</h5>
                        </div>
                    </div>
                </div>
            </div>
            <HubspotContactForm 
                region= "na1"
                portalId = "44519729"
                formId= "861fc363-08dc-4423-af00-99738d1dc9ea"
            />
        </div>
    </section>
  )
}

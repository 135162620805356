/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react'
import './Benefits.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons'

export default function Benefits() {
  return (
    <section className='section' id='benefits'>
      <h2 className='header benefits-header'>
        The Benefits of Health Benefits
      </h2>
      <div className='benefits-text-container'>
        <p className='benefits-text'>
        As a last-mile delivery independent contractor, you understand the importance of keeping talented, reliable employees on your team. High turnover can cause delays and disruptions to your delivery service, ultimately affecting your bottom line.
        </p>
        <p className='benefits-text'>
        One effective way to retain your best employees is by offering a generous health, dental, and vision insurance plan. Not only will this package benefit your team's physical health, but it can also improve their overall job satisfaction and decrease the likelihood of them seeking employment elsewhere.
        </p>
        <p className='benefits-text'>
        Studies have shown that employees are more likely to stay with an employer who offers a comprehensive healthcare package. By investing in the well-being of your employees, you will not only improve their lives but also strengthen your business, avoid high turnover costs and create a loyal, productive workforce.
        </p>
        <ul className='benefits-list'>
          <h3 className='list-header'>The Benefits Simplified:</h3>
          <div className='listitem-row'>
            <FontAwesomeIcon className='list-svg' icon={faCheck} />
            <li className='benefits-list-item'>Employees are provided a generous healthcare plan</li>
          </div>
          <div className='listitem-row'>
            <FontAwesomeIcon className='list-svg' icon={faCheck}/>
            <li className='benefits-list-item'>Higher employee retention rates</li>
          </div>
          <div className='listitem-row'>
            <FontAwesomeIcon className='list-svg' icon={faCheck}/>
            <li className='benefits-list-item'>Higher workplace productivity</li>
          </div>
        </ul>
      </div>
      </section>
  )
}

/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react'
import './Hero.scss'

export default function Hero() {
  return (
    <section id='home' className='hero-section'>
        <div className='hero-img'>
            <div className='hero-text-box'>
                <h2 className='hero-header' style={{color: 'white'}}>Access Affordable Employee Healthcare Benefits With SPTA</h2>
                <p className='hero-body'>
                    Comprehensive Anthem Health Plans for Last-Mile Delivery Independent Contractors
                </p>
                <div className='btn-row'>
                    <a href='#contact' className='hero-btn'>Contact Us &#8595;</a>
                </div>
            </div>
        </div>
    </section>
  )
}

/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react'
import './Footer.scss'
import logo from '../../assets/SPTA Logo_Vertical_FINAL.jpg'
import { NavLink } from 'react-router-dom'

export const scrollToTop = () => {
  window.scrollTo({top: 0, behavior: 'instant'})
}


export default function Footer() {


  return (
    <div className='footer'>
        <p className='copyright-text'>© 2023 Small Package Transportation Association.</p>
        <div className='privacy-row'>
        <NavLink onClick={scrollToTop} className='privacy-link' to={'/privacy'}>
            Privacy Policy
        </NavLink>
        <NavLink  onClick={scrollToTop} className='privacy-link' to={'/termsofservice'}>
            Terms & Conditions
        </NavLink>
        </div>
        <img className='footer-logo' src={logo}/>
    </div>
  )
}

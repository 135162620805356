/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faNotesMedical, faTruck } from '@fortawesome/free-solid-svg-icons';
import image from '../../assets/about.avif'
import './About.scss'

export default function About() {

  const cards = [
    {
      header: 'Budget-Friendly Healthcare Plans',
      icon: faNotesMedical,
      body: `The Small Package Transportation Association was founded to provide access to affordable healthcare plans for last-mile delivery independent contractors. 
      Our comprehensive plans include vision, dental, life, and short-term disability insurance. These plans are designed to be affordable and accessible, so that 
      independent contractors can offer the benefits drivers want while staying within budget.`
    },
    {
      header: 'Driver Retention Tool',
      icon: faTruck,
      body: `Health plans like these are an excellent driver retention tool. By offering comprehensive healthcare plans to your employees, you can differentiate your workplace from other options 
      and ensure that your drivers stay with you for the long haul. Our plans are designed to be affordable and accessible, so that you can provide the best possible benefits to your employees.`
    },
    {
      header: 'Get Access Today',
      icon: faKey,
      body: `Don't wait any longer to get access to affordable healthcare plans for your last-mile delivery business. Sign up with the Small
      Package Transportation Association today and access comprehensive health plans that will give your drivers what they want and what
      they need for long-term employment satisfaction.`
    }
  ]



  return (
    <section className='section' id='about'>
      <h1 className='header about-header'>About</h1>
      <div className='about-container'>
            <img src={image} className='about-img'/>
          <div className='cards-container'>
            {cards.map(card=>{
              return <AboutUsCard key={card.header} header={card.header} icon={card.icon} body={card.body}/>
            })}
          </div>
      </div>
    </section>
  )
}

function AboutUsCard({header, icon, body}){
  return (
      <div className='about-card'>
          <div className='card-flex'>
              <div className='svg-container'>
                  <FontAwesomeIcon className='svg' icon={icon}/>
              </div>
              <h3 className='card-about-header'>{header}</h3>
          </div>
          <div className=''>
              <p className='about-text'>
                 {body}
              </p>
          </div>
      </div>
  )
}
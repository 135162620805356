/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react'
import './Products.scss'
import anthemLogo from '../../assets/Anthem-Blue-Cross-Logo.png'
import image from '../../assets/product-img.avif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faGlasses, faKitMedical, faPerson, faTooth, faUserInjured } from '@fortawesome/free-solid-svg-icons'

export default function Products() {
  return (
    <section className='section' id='products'>
      <h2 className='header product-header'>Our Products</h2>
      <div className='products-container'>
          <div className='text-container'>
            <img className='partner-logo' src={anthemLogo}></img>
              <p className='text'>
              Introducing an exclusive group insurance plan designed for last-mile delivery independent contractors to provide top-notch benefits to their employees at a discounted rate. 
              Created by the Small Package Transportation Association (SPTA), our plan offers a range of coverage options including health plans underwritten by Anthem Blue Cross and Blue Shield. 
              </p>
              <br/>
              <p className='text'>
              In addition to life and short-term disability coverage provided by employers, employees can choose to have dental, vision, and voluntary life coverage paid either by their employer or through payroll deduction. 
              These are the benefits your drivers are asking for, and you can make them available to them. Elevate your employee benefits game with SPTA's comprehensive insurance plan.
              </p>
              <br/>
              
              
          </div>
          {/* <div className='product-img'> */}
              <img className='product-img' src={image}/>
      </div>
      <div className='our-plans-icons'>
        <h4 className='row-header'>Our plans have access to:</h4>
        <div className='row-container'>
          <div className='icons-row'>
              <div className='icon-row'>
                  <FontAwesomeIcon className='row-svg' icon={faKitMedical} />
                  <p className='row-icon-text'>Health</p>
              </div>
              <div className='icon-row'>
                  <FontAwesomeIcon className='row-svg' icon={faTooth} />
                  <p className='row-icon-text'>Dental</p>
              </div>
              <div className='icon-row'>
                    <FontAwesomeIcon className='row-svg' icon={faEye}/>
                    <p className='row-icon-text'>Vision</p>
              </div>
                <div className='icon-row'>
                    <FontAwesomeIcon className='row-svg' icon={faPerson}/>
                    <p className='row-icon-text'>Life</p>
                </div>
                <div className='icon-row'>
                    <FontAwesomeIcon className='row-svg' icon={faUserInjured}/>
                    <p className='row-icon-text'>Short-term Disability</p>
                </div>
              </div>
          </div>
      </div>
    </section>
  )
}

/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react'
import './Pricing.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlus, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import { Table } from 'react-bootstrap'

export default function Pricing() {


const dentalRates = ['21.49', '43.84', '49.50', '75.24'];
const visionRates = ['7.29', '14.59', '13.46', '21.13'];
const life = '5.35';
const disability = '13.00'

  return (
    <section className='section' id='pricing'>
        <h2 className='header pricing-header'>Pricing</h2>
        <div className='pricing-table-container'>
            <PricingTable noDollar title={'Medical'} prices={['Contact Us', 'Contact Us', 'Contact Us', 'Contact Us']}/>
        <p className='price-disclaimer'>Medical rates are subject to various factors such as group size and also employer location.</p>
            <PricingTable title={'Dental'} prices={dentalRates}/>
            <PricingTable title={'Vision'} prices={visionRates}/>
            <PricingTableSingleRow title={'Life'} price={life}/>
            <PricingTableSingleRow title={'Short-Term Disablity'} price={disability}/>
        </div>
        {/* <h2 className='header'>Testimonials</h2>
        <div className='testimonial-grid'>
            <TestimonialCard/>
            <TestimonialCard/>
            <TestimonialCard/>
            <TestimonialCard/>
        </div> */}
      </section>
  )
}


function PricingTable({prices, title, noDollar}){
    return (
        <Table bordered striped className='pricing-table'>
            <tbody>
                <tr>
                    <th className='table-header'>{title}</th>
                    <th className='table-header'>Per Month</th>
                </tr>
                <tr>
                    <td className='colored-column row-text'>Employee (alone)</td>
                    <td className='row-text'>{!noDollar && '$'}{prices[0]}</td>
                </tr>
                <tr>
                    <td className='colored-column row-text'>Employee + spouse</td>
                    <td className='row-text'>{!noDollar && '$'}{prices[1]}</td>
                </tr>
                <tr>
                    <td className='colored-column row-text'>Employee + children</td>
                    <td className='row-text'>{!noDollar && '$'}{prices[2]}</td>
                </tr>
                <tr>
                    <td className='colored-column row-text'>Employee + family</td>
                    <td className='row-text'>{!noDollar && '$'}{prices[3]}</td>
                </tr>
            </tbody>
        </Table>
    )
}

function PricingTableSingleRow({price, title}){
    return (
        <Table bordered striped className='pricing-table'>
            <tbody>
                <tr>
                    <th className='table-header'>{title}</th>
                    <th className='table-header'>Per Month</th>
                </tr>
                <tr>
                    <td className='colored-column row-text'>Employee (alone)</td>
                    <td className='row-text'>${price}</td>
                </tr>
            </tbody>
        </Table>
    )
}

function TestimonialCard({name, company, testimonial}){
    return (
        <div className='testimonial-card'>
            <FontAwesomeIcon className='icon-quotes' icon={faQuoteLeft}/>
            <div className='testimonial-text-container'>
                <p className='testimonial-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eu elit ipsum. Curabitur blandit dictum dui, non dictum enim volutpat vel. Non dictum enim volutpat vel.</p>
            </div>
            <div>
                <h5 style={{textAlign: 'right'}}>- Name</h5>
                <h6 style={{fontStyle: 'italic', textAlign: 'right'}}>Company</h6>
            </div>
        </div>
    )
}